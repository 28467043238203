:root {
    --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

[data-reach-dialog-content] {
  outline: none;
}
