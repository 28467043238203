.button {
    flex: 2 2 0%;
}

@screen md {
    .button {
        flex: 1 1 0%;
    }
}

.lines {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzUuNzg5IDU4LjY1Ij4KICAgIDxwYXRoIGlkPSJwcmVmaXhfX1BmYWRfMTA0NyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtZGFzaGFycmF5PSIxMCAxMCIgZD0iTS03NDc3LjIxMiAxMDI3LjZzNzUuOTQ0LTczLjk3MyAxOTMuODY5LTE3LjQgMTU2LjQ2OSAzOS4xMzMgMTgxLjE0OSAwIiBkYXRhLW5hbWU9IlBmYWQgMTA0NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzQ3Ny41NjEgLTk4OC4yMTMpIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

@screen md {
    .lines {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjc5LjkwMyAxNTkuNzY0Ij4KICAgIDxwYXRoIGlkPSJwcmVmaXhfX1BmYWRfMTA0OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtZGFzaGFycmF5PSIxMCAxMCIgZD0iTS0xNjcwIDE4LjQxMnMyNTYuODc2LTE3OS44NTUgNjI0LjM4NC05LjYwOSA1ODUuODQgNDIuNDUyIDY1NC45MjktMTAuMjM3IiBkYXRhLW5hbWU9IlBmYWQgMTA0OSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTY3MC4yODcgNjQuOTcyKSIvPgo8L3N2Zz4K');
    }
}
