/* purgecss start ignore */
@tailwind base;
/* purgecss end ignore */

@tailwind components;
@tailwind utilities;
@tailwind screens;

@import './fonts.css';

html {
    font-size: 17px;
    line-height: 1.18;
}

body {
    @apply font-body;
}

@import './reach-skip-nav.css';
@import './reach-dialog.css';
