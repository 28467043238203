@keyframes background-gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.loading {
    background: linear-gradient(-45deg, theme('colors.gray.500'), theme('colors.gray.300'), theme('colors.gray.500'), theme('colors.white'));
    background-size: 400% 400%;
    animation: background-gradient 8s ease infinite;
}
